import { useEffect, useState, useRef } from "react"
import { createPortal } from "react-dom"

import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"


function Intellicharge({

}) {

    const [intellichargeImg, setIntellichargeImg] = useState("payment")
    const [expandedUser, setExpandedUser] = useState("none")
    const [delayedExpandedUser, setDelayedExpandedUser] = useState("none")
    const [isVisible, setIsVisible] = useState(false);
    const [clickedImg, setClickedImg] = useState("none")

    const card1Ref = useRef(null)
    const card2Ref = useRef(null)
    const card3Ref = useRef(null)
    const card4Ref = useRef(null)
    const firstAppFact = useRef(null)

    function changeExpandedUser(page) {
        if (expandedUser === page) {
            setExpandedUser("none")
            setDelayedExpandedUser("none")
        } else if (expandedUser === "none") {
            setExpandedUser(page)
            setTimeout(() => {
                setDelayedExpandedUser(page)
            }, 70)
        } else {
            setExpandedUser("none")
            setDelayedExpandedUser("none")
            setTimeout(() => {
                setExpandedUser(page)
                setTimeout(() => {
                    setDelayedExpandedUser(page)
                }, 70)
            }, 150)
        }
    }

    const scrollToSection = (part) => {
        const app = document.getElementById('mobileApp');
        const management = document.getElementById('management')
        const chargers = document.getElementById('chargers')
        if (part === "mobileApp") {
            if (app) {
                app.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (part === "management") {
            if (management) {
                management.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            if (chargers) {
                chargers.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const onVisible = () => {
        if (card1Ref.current) {
            card1Ref.current.style.transition = "all .2s"
            card1Ref.current.style.transform = "translateY(0)"
        }
        if (card2Ref.current) {
            card2Ref.current.style.transition = "all .4s"
            card2Ref.current.style.transform = "translateY(0)"
        }
        if (card3Ref.current) {
            card3Ref.current.style.transition = "all .6s"
            card3Ref.current.style.transform = "translateY(0)"
        }
        if (card4Ref.current) {
            card4Ref.current.style.transition = "all .8s"
            card4Ref.current.style.transform = "translateY(0)"
        }
    }
    
    const visibleButton = () => {
        if(!isVisible) {
            setIsVisible(true)
            setTimeout(() => {
                changeExpandedUser("app")
            }, 100)
        }
    }

    const onHidden = () => {
        console.log("hidden")
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (onVisible) onVisible();
                    } 
                });
            },
            {
                threshold: 0.3,
            }
        );
        const buttonObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (visibleButton) visibleButton();
                    } 
                });
            },
            {
                threshold: 1,
            }
        );
        if (card1Ref.current) {
            observer.observe(card1Ref.current);
        }
        if(firstAppFact.current) {
            buttonObserver.observe(firstAppFact.current)
        }
        return () => {
            if (card1Ref.current) {
                observer.unobserve(card1Ref.current);
            }
        };
    }, [visibleButton, onHidden]);


    return (
        <div className="w-full h-full bg-gray-100">
           <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"droneShotForest.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-[60px] leading-[60px] font-medium font-blender">Bring intelligence to EV Charging</h2>
                        <h3 className="text-2xl font-regular mt-2 text-white">IntelliCharge utilizes artificial intelligence to leverage EV charging to bolster sustainability, reliability, and security.</h3>
                    </div>
                </div>
            </div>
            <div className="w-full bg-cover bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pl-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28  ">
                <h2 className="text-4xl font-medium text-black">How Does IntelliCharge work?</h2>
                <h3 className="text-xl mt-2">IntelliCharge is an end-to-end charging solution, including EV charging hardware, a mobile app, a charging management platform, and a cloud-based control center.</h3>
                <div className="w-full mt-8 flex items-center justify-center">
                    <img src="/images/intellicharge_dashboard_graphic_7_31.png" className="w-[100%] sm:w-[90%] md:w-[80%]"></img>
                </div>
            </div>
            <div className="w-full">
                    <img src="/images/wavebgTall.png" className="w-full h-72"></img>
            </div>
            <div id="management" className="h- w-full bg-baseGray pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pl-56 pt-8 md:pt-16 laptop:pt-0 large-laptop:pt-0 desktop:pt-12 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48 grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <h1 className="text-textWhite font-semibold text-4xl">Management Platform</h1>
                    <h3 className="mt-4 text-textWhite text-xl">IntelliCharge is fully customizable to cater to your individual charging needs.</h3>
                    {/* <div className="w-full pl-4 pr-4 md:pl-8 md:pr-8 lg:pl-16 lg:pr-16 xl:pl-48 xl:pr-48 p-12 pt-4 mt-16 border-[2px] bg-secondary bg-opacity-10 border-secondary rounded-md">
                        <img className="w-[80%] mt-8 border-[4px] border-baseGray rounded-lg" src="/images/intellichargePaymentSS.png"></img>
                    </div> */}
                    {/* <div className="w-full flex items-center justify-center">
                        <img className="w-[60%] mt-8 border-[4px] border-baseGray rounded-lg" src="/images/intellichargePaymentSSDarkened.png"></img>
                    </div> */}
                    <div className="grid grid-cols-12 gap-4 w-full mt-16">
                        <div ref={card1Ref} className="translate-y-[30px] col-span-12 md:col-span-6 large-laptop:col-span-3 bg-opacity-10 bg-gray-200 rounded-lg">
                            <button onClick={() => setClickedImg("monitor")} className="hidden md:block overflow-hidden">
                                <img className="w-full rounded-tl-lg rounded-tr-lg hover:scale-[102%] duration-150" src="/images/intellichargePaymentSSDarkened.png"></img>
                            </button>
                            <div className=" w-full pt-6 pb-6 p-3 ">
                                <div className="flex items-center">
                                    <h2 className="text-xl text-textWhite font-semibold mb-1 text-start">Monitor Chargers</h2>
                                </div>
                                <p className="text-secondaryTextWhite text-start">Access real-time and historical data for each EV charger.</p>
                            </div>
                        </div>
                        <div ref={card2Ref} className="translate-y-[30px] col-span-12 md:col-span-6 large-laptop:col-span-3 bg-opacity-10 bg-gray-200 rounded-lg">
                            <button onClick={() => setClickedImg("monitor")} className="hidden md:block overflow-hidden">
                                <img className="w-full rounded-tl-lg rounded-tr-lg hover:scale-[102%] duration-150" src="/images/intellichargePaymentSSDarkened.png"></img>
                            </button>
                            <div className=" w-full pt-6 pb-6 p-3 ">
                                <div className="flex items-center">
                                    <h2 className="text-xl text-textWhite font-semibold mb-1 text-start">Custom Payments</h2>
                                </div>
                                <p className="text-secondaryTextWhite text-start">Create custom payment procedures based on energy, parking, and EV owner.</p>
                            </div>
                        </div>
                        <div ref={card3Ref} className="translate-y-[30px] col-span-12 md:col-span-6 large-laptop:col-span-3 bg-opacity-10 bg-gray-200 rounded-lg">
                            <button onClick={() => setClickedImg("monitor")} className="hidden md:block overflow-hidden">
                                <img className="w-full rounded-tl-lg rounded-tr-lg hover:scale-[102%] duration-150" src="/images/intellichargePaymentSSDarkened.png"></img>
                            </button>
                            <div className=" w-full pt-6 pb-6 p-3 ">
                                <div className="flex items-center">
                                    <h2 className="text-xl text-textWhite font-semibold mb-1 text-start">Intelligent Controls</h2>
                                </div>
                                <p className="text-secondaryTextWhite text-start">Optimize charging for dynamic load balancing and energy asset integration.</p>
                            </div>
                        </div>
                        <div ref={card4Ref} className="translate-y-[30px] col-span-12 md:col-span-6 large-laptop:col-span-3 bg-opacity-10 bg-gray-200 rounded-lg">
                            <button onClick={() => setClickedImg("monitor")} className="hidden md:block overflow-hidden">
                                <img className="w-full rounded-tl-lg rounded-tr-lg hover:scale-[102%] duration-150" src="/images/intellichargePaymentSSDarkened.png"></img>
                            </button>
                            <div className=" w-full pt-6 pb-6 p-3 ">
                                <div className="flex items-center">
                                    <h2 className="text-xl text-textWhite font-semibold mb-1 text-start">Sustainability Oriented</h2>
                                </div>
                                <p className="text-secondaryTextWhite text-start">Set your chargers to follow local renewable energy generation.</p>
                            </div>
                        </div>
                        {/* <button className="col-span-6 lg:col-span-3 bg-gray-200 w-full bg-opacity-10 w-full p-3 pb-8 pt-8 flex flex-col items-center mr-4 rounded-lg border-[0px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                            </svg>
                            <h2 className="text-xl text-textWhite font-semibold mt-2 mb-1">Custom Payments</h2>
                            <p className="text-textWhite text-center">Create custom payment procedures based on energy, parking, and EV owner.</p>
                        </button>
                        <button className="col-span-6 lg:col-span-3 bg-gray-200 w-full bg-opacity-10 w-full p-3 pb-8 pt-8 flex flex-col items-center mr-4 rounded-lg border-[0px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                            </svg>
                            <h2 className="text-xl text-textWhite font-semibold mt-2 mb-1">Intelligent Controls</h2>
                            <p className="text-textWhite text-center">Optimize charging for dynamic load balancing and energy asset integration.</p>
                        </button>
                        <button className="col-span-6 lg:col-span-3 bg-gray-200 w-full bg-opacity-10 w-full p-3 pb-8 pt-8 flex flex-col items-center mr-4 rounded-lg border-[0px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                            </svg>
                            <h2 className="text-xl text-textWhite font-semibold mt-2 mb-1">Sustainability Oriented</h2>
                            <p className="text-textWhite text-center">Set your chargers to follow local renewable energy generation.</p>
                        </button> */}
                    </div>
                    <button className="flex items-center bg-primaryAccent pl-3 pr-3 p-2 rounded-md mt-12">
                        <h2 className="text-white text-lg font-blender text-white">View Demo</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
            </div>
            <div id="mobileApp" className="w-full bg-baseWhite pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pl-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48 grid grid-cols-2 gap-4">
                <div className="col-span-2 lg:col-span-1">
                    <h1 className="text-black text-4xl font-semibold">IntelliCharge Mobile App</h1>
                    <h3 className="mt-4 text-black text-xl">The IntelliCharge Mobile App creates a seamless and simple charging experience.</h3>
                    <button ref={firstAppFact} onClick={() => changeExpandedUser('app')} className={`${expandedUser === "app" ? "h-36" : "h-14"} duration-200 ease-in-out translate border-[1px] w-full lg:w-96 mt-12 justify-between p-3 rounded-sm border-baseGray bg-primaryAccent bg-opacity-10`}>
                        <div className="flex items-center justify-between w-full">
                            <h2 className="text-black text-lg font-blender">1. Effortlessly Charge Your EV</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${expandedUser === "app" && "rotate-90"} duration-200 ease-in-out size-6 text-black mt-[2px]`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </div>
                        {delayedExpandedUser === "app" && (
                            <div className="h-[88px] w-full text-start pl-[30px] pt-2">
                                <h2 className="text-black">Simply scan the QR code on the faceplate of the charger, enter your departure time, and plug in.</h2>
                            </div>
                        )}
                    </button>
                    <div></div>
                    <button onClick={() => changeExpandedUser('chargers')} className={`${expandedUser === "chargers" ? "h-36" : "h-14"} duration-200 ease-in-out translate border-[1px] w-full lg:w-96 mt-4 justify-between p-3 rounded-sm border-baseGray bg-primaryAccent bg-opacity-10`}>
                        <div className="flex items-center justify-between w-full">
                            <h2 className="text-black text-lg font-blender">2. Track Your Data</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${expandedUser === "chargers" && "rotate-90"} duration-200 ease-in-out size-6 text-black mt-[2px]`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </div>
                        {delayedExpandedUser === "chargers" && (
                            <div className="h-[88px] w-full text-start pl-[30px] pt-2">
                                <h2 className="text-black">Monitor your charge in real-time  and view your charging session history across analytics with IntelliCharge chargers.</h2>
                            </div>
                        )}
                    </button>
                    <div></div>
                    <button onClick={() => changeExpandedUser('savings')} className={`${expandedUser === "savings" ? "h-36" : "h-14"} duration-200 ease-in-out translate border-[1px] w-full lg:w-96 mt-4 justify-between p-3 rounded-sm border-baseGray bg-primaryAccent bg-opacity-10`}>
                        <div className="flex items-center justify-between w-full">
                            <h2 className="text-black text-lg font-blender">3. Plan Your Sessions</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${expandedUser === "savings" && "rotate-90"} duration-200 ease-in-out size-6 text-black mt-[2px]`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </div>
                        {delayedExpandedUser === "savings" && (
                            <div className="h-[88px] w-full text-start pl-[30px] pt-2">
                                <h2 className="text-black">Use the IntelliCharge charger map to find your nearest available charger, get directions, and get plugged in as well.</h2>
                            </div>
                        )}
                    </button>
                    <div></div>
                    <button className="flex items-center bg-primaryAccent pl-3 pr-3 p-2 rounded-md mt-12">
                        <h2 className="text-white text-lg font-blender text-white">IntelliCharge App</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
                <div className="hidden lg:block col-span-2 mt-24 lg:col-span-1 lg:mt-0">
                    {/* <h2 className="text-black w-full text-center font-blender -mb-8">Intellicharge Mobile App</h2> */}
                    <div className="relative flex sm:justify-between lg:justify-end -mt-8">
                        <div className="absolute left-0 flex flex-col w-[80%] items-center ml-4 z-[100]">
                            {/* <h2 className="text-black">Intellicharge Mobile App</h2> */}
                            <img src="/images/IntellichargeiPhoneNoBG.png" className="w-56 mt-24" />
                        </div>
                        <div className="flex flex-col w-[80%] items-center ml-4 z-[90] ">
                            <img src="/images/IntellichargeiPhoneNoBGWhite.png" className="w-56 mt-7 hidden md:block  " />
                        </div>
                    </div>
                </div>
            </div>
            <div id="chargers" className="h- w-full bg-baseGray pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pl-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48 grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <h1 className="text-textWhite text-4xl font-semibold">IntelliCharge Chargers</h1>
                    <h3 className="mt-4 text-textWhite text-xl">Sleek and robust chargers for easy integration Level 2 charging at 40A and 80A.</h3>
                </div>
                <div className="col-span-2 sm:col-span-1 mt-16">
                    <div className="w-full ">
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[0px] border-textWhite">
                            <h2 className="text-textWhite font-blender text-xl col-span-3">Robust Connectivity</h2>
                            <h3 className="text-secondaryTextWhite ">Connect your charger to the cloud with WiFi or cellular to ensure reliable network connection.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[0px] border-textWhite">
                            <h2 className="text-textWhite font-blender text-xl col-span-3">Comprehensive Certifications </h2>
                            <h3 className="text-secondaryTextWhite ">UL certified, OCPP compliant, fully weatherized.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-textWhite">
                            <h2 className="text-textWhite font-blender text-xl col-span-3">Secure Design</h2>
                            <h3 className="text-secondaryTextWhite ">Simple LED indicators effectively communicate statuses while reducing susceptibility to vandalism.</h3>
                        </div>
                    </div>
                    <button className="flex items-center bg-primaryAccent pl-3 pr-3 p-2 rounded-md mt-20">
                        <h2 className="text-white text-lg font-blender text-white">Learn More</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
                <div className="w-full flex items-center justify-end col-span-1 hidden sm:block">
                    <div className="large-laptop:-mt-20 flex items-center justify-center">
                        {/* <h2 className="text-textWhite w-full text-center font-blender -mb-20">40A-80A Grizzlie EV Charger</h2> */}
                        <img className="w-96 border-[4px] border-baseGray rounded-lg" src="/images/chargerFrontFacing-removebg-preview.png"></img>
                    </div>
                </div>
            </div>
            {/* <div className="h- w-full bg-baseWhite p-72 pt-24 pb-48 grid grid-cols-2 gap-4">

            </div> */}
            <Footer />
            {clickedImg !== "none" && (
                createPortal(
                    <div className={`right-0 top-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-70 fixed z-[9999] pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pl-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48`}>
                        <div className="relative p-2 w-[90%] large-laptop:w-[92%] desktop:w-[80%]">
                            <button onClick={() => setClickedImg("none")} className="absolute -top-12 -right-12 h-12  w-12 rounded-full bg-white z-50 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                            {clickedImg === "monitor" && (
                                <img className="w-full" src="/images/intellichargePaymentSS.png" alt="Monitor" />
                            )}
                        </div>
                    </div>
                    , document.body
                )
            )}
        </div>
    )
}

export default Intellicharge
import { useEffect, useState, useRef } from "react"
import { createPortal } from "react-dom"

import VideoBackground from "../home/videoBackground"
import Footer from "../globalComponents/footer"

function NewIntellicharge({

}) {

    const [intellichargeImg, setIntellichargeImg] = useState("payment")
    const [expandedUser, setExpandedUser] = useState("none")
    const [delayedExpandedUser, setDelayedExpandedUser] = useState("none")
    const [isVisible, setIsVisible] = useState(false);
    const [clickedImg, setClickedImg] = useState("none")

    const card1Ref = useRef(null)
    const card2Ref = useRef(null)
    const card3Ref = useRef(null)
    const card4Ref = useRef(null)
    const firstAppFact = useRef(null)

    function changeExpandedUser(page) {
        if (expandedUser === page) {
            setExpandedUser("none")
            setDelayedExpandedUser("none")
        } else if (expandedUser === "none") {
            setExpandedUser(page)
            setTimeout(() => {
                setDelayedExpandedUser(page)
            }, 70)
        } else {
            setExpandedUser("none")
            setDelayedExpandedUser("none")
            setTimeout(() => {
                setExpandedUser(page)
                setTimeout(() => {
                    setDelayedExpandedUser(page)
                }, 70)
            }, 150)
        }
    }

    const scrollToSection = (part) => {
        const app = document.getElementById('mobileApp');
        const management = document.getElementById('management')
        const chargers = document.getElementById('chargers')
        if (part === "mobileApp") {
            if (app) {
                app.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (part === "management") {
            if (management) {
                management.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            if (chargers) {
                chargers.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const onVisible = () => {
        if (card1Ref.current) {
            card1Ref.current.style.transition = "all .2s"
            card1Ref.current.style.transform = "translateY(0)"
        }
        if (card2Ref.current) {
            card2Ref.current.style.transition = "all .4s"
            card2Ref.current.style.transform = "translateY(0)"
        }
        if (card3Ref.current) {
            card3Ref.current.style.transition = "all .6s"
            card3Ref.current.style.transform = "translateY(0)"
        }
        if (card4Ref.current) {
            card4Ref.current.style.transition = "all .8s"
            card4Ref.current.style.transform = "translateY(0)"
        }
    }

    const visibleButton = () => {
        if (!isVisible) {
            setIsVisible(true)
            setTimeout(() => {
                changeExpandedUser("app")
            }, 100)
        }
    }

    const onHidden = () => {
        console.log("hidden")
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (onVisible) onVisible();
                    }
                });
            },
            {
                threshold: 0.3,
            }
        );
        const buttonObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (visibleButton) visibleButton();
                    }
                });
            },
            {
                threshold: 1,
            }
        );
        if (card1Ref.current) {
            observer.observe(card1Ref.current);
        }
        if (firstAppFact.current) {
            buttonObserver.observe(firstAppFact.current)
        }
        return () => {
            if (card1Ref.current) {
                observer.unobserve(card1Ref.current);
            }
        };
    }, [visibleButton, onHidden]);


    return (
        <div className="w-full h-full bg-gray-100">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"droneShotForest.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-4xl leading-[34px] mobile:text-[60px] mobile:leading-[60px] w-full lg:w-[70%] xl:w-[53%] font-medium font-blender">Bring intelligence to EV Charging</h2>
                        <h3 className="text-md mobile:text-xl font-regular mt-2 w-full lg:w-[70%] xl:w-[53%] text-white font-ralewayReg">IntelliCharge utilizes artificial intelligence to leverage EV charging to bolster sustainability, reliability, and security.</h3>
                    </div>
                </div>
            </div>
            {/* <div className="w-full bg-baseGray laptop:min-h-[100vh] bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 ">
                <div className="">
                <h3 className=" text-xl laptop:text-2xl text-textWhite mt-2 font-ralewayReg">IntelliCharge is an end-to-end charging solution, including EV charging hardware, a mobile app, a charging management platform, and a cloud-based control center.</h3>
                </div>
                <div className="w-full mt-24 flex items-center justify-center">
                    <img src="/images/intellicharge_dashboard_graphic_7_31.png" className="hidden mobile:block w-[100%] laptop:w-[90%] md:w-[80%]"></img>
                </div>
            </div> */}
              <div className="w-full laptop:min-h-[100vh] bg-baseGray bg-top px-6 md:px-10 laptop:px-16 large-laptop:px-24 desktop:px-42 py-8 md:py-16 laptop:py-24 large-laptop:py-32 desktop:py-32 flex flex-col items-center justify-center">
                <h2 className="z-40 text-xl laptop:text-2xl text-center text-textWhite font-ralewayReg w-full lg:w-[70%] large-laptop:w-[53%] desktop:w-[40%]">
                IntelliCharge is an end-to-end charging solution, including EV charging hardware, a mobile app, a charging management platform, and a cloud-based control center.
                </h2>
                <div className="w-full flex items-center justify-center large-laptop:px-32 desktop:pr-48 desktop:pl-48">
                    <img src="/images/intellicharge_dashboard_graphic_7_31.png" className="mt-12 laptop:mt-12 hidden mobile:block w-[100%] laptop:w-[90%] md:w-[80%]"></img>
                </div>
            </div>
            {/* <div className="w-[100vw] h-64 -mt-24 mb-6">
                <img src="/images/blueToWhiteBlend.png" className="w-full bg-cover"></img>
            </div> */}
            <div className="w-full min-h-[100vh] grid grid-cols-12 gap-8 large-laptop:gap-20 bg-baseWhite bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <div className="laptop:col-span-6 col-span-12 flex items-center">
                    <div className="w-full ">
                        <div>
                            <h1 className="text-4xl leading-[34px] mobile:text-5xl mobile:leading-[50px] font-semibold font-blender">Management Platform</h1>
                            <h2 className="text-3xl mt-3 font-ralewayReg">IntelliCharge is fully customizable to cater your facility's charging needs.</h2>
                        </div>
                        <div className="mt-12 w-full pt-6 pb-6 border-t-[2px] border-b-[1px] border-secondary">
                            <h2 className="text-black text-3xl font-medium col-span-3 font-blender">Monitor Chargers</h2>
                            <h3 className="text-lg">Access real-time and historical data for each EV charger.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-secondary">
                            <h2 className="text-black text-3xl font-medium col-span-3 font-blender">Custom Payments</h2>
                            <h3 className="text-lg">Create custom payment procedures based on energy, parking, and EV owner.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-secondary">
                            <h2 className="text-black text-3xl font-medium col-span-3 font-blender">Intelligent Controls</h2>
                            <h3 className="text-lg">Optimize charging for dynamic load balancing and energy asset integration.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[2px] border-secondary">
                            <h2 className="text-black text-3xl font-medium col-span-3 font-blender">Sustainability Oriented</h2>
                            <h3 className="text-lg">Set your chargers to follow local renewable energy generation.</h3>
                        </div>
                    </div>
                </div>
                <div className="hidden laptop:flex laptop:col-span-6 items-center justify-center">
                    <img src="/images/intellicharge_dashboard_graphic.png" className="w-[100%]"></img>
                </div>
            </div>
            <div className="w-full min-h-[100vh] grid grid-cols-12 gap-8 large-laptop:gap-20 bg-baseGray bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <div className="hidden laptop:flex laptop:col-span-6 items-center justify-center">
                    <img src="/images/intellicharge_mobileapp_collage.png" className="w-[90%] sm:w-[80%] md:w-[70%]"></img>
                </div>
                <div className="laptop:col-span-6 col-span-12 flex items-center">
                    <div className="w-full ">
                        <div>
                            <h1 className="text-4xl leading-[34px] text-textWhite mobile:text-5xl mobile:leading-[50px] font-semibold font-blender">IntelliCharge Mobile App</h1>
                            <h2 className="text-3xl text-textWhite mt-3 font-ralewayReg">The IntelliCharge Mobile App creates a seamless and simple charging experience.</h2>
                        </div>
                        <div className="mt-12 w-full pt-6 pb-6 border-t-[2px] border-b-[1px] border-secondary">
                            <h2 className="text-textWhite font-blender text-3xl col-span-3">Effortlessly Charge</h2>
                            <h3 className="text-lg text-secondaryTextWhite">Scan the QR code on the charger, enter your departure time, and plug in.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-secondary">
                            <h2 className="text-textWhite font-blender text-3xl col-span-3">Track your Data</h2>
                            <h3 className="text-lg text-secondaryTextWhite">Monitor your charge in real-time and view your charging session history.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-secondary">
                            <h2 className="text-textWhite font-blender text-3xl col-span-3">Plan your Sessions</h2>
                            <h3 className="text-lg text-secondaryTextWhite">Use the charger map to find your nearest available charger and get directions.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full min-h-[100vh] grid grid-cols-12 gap-8 large-laptop:gap-20 bg-baseWhite bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <div className="laptop:col-span-8 col-span-12 flex items-center">
                    <div className="w-full ">
                        <div>
                            <h1 className="text-4xl leading-[34px] mobile:text-5xl mobile:leading-[50px] font-semibold font-blender">Next Generation Intelligent Chargers</h1>
                            <h2 className="text-3xl mt-3 font-ralewayReg">Sleek and robust chargers for easy integration Level 2 charging at 40A and 80A.</h2>
                        </div>
                        <div className="mt-12 w-full pt-6 pb-6 border-t-[2px] border-b-[1px] border-secondary">
                            <h2 className="text-black font-blender text-3xl font-medium col-span-3">Robust Connectivity</h2>
                            <h3 className="text-lg">Connect your charger to the cloud with WiFi or cellular to ensure reliable network connection.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-secondary">
                            <h2 className="text-black font-blender text-3xl font-medium col-span-3">Comprehensive Certifications</h2>
                            <h3 className="text-lg">UL certified, OCPP compliant, fully weatherized.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[2px] border-secondary">
                            <h2 className="text-black font-blender text-3xl font-medium col-span-3">Secure Design</h2>
                            <h3 className="text-lg">Simple LED indicators effectively communicate statuses while reducing susceptibility to vandalism.</h3>
                        </div>
                    </div>
                </div>
                {/* <div className="hidden laptop:flex laptop:col-span-6 items-center justify-center">
                    <img src="/images/chargerFrontFacing-removebg-preview.png" className="w-[100%] sm:w-[80%] md:w-[80%]"></img>
                </div> */}
            </div>
            {/* <div id="chargers" className="min-h-[100vh] w-full bg-baseWhite pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 grid grid-cols-2 gap-4">
                <div className="col-span-2 sm:col-span-1 mt-16">
                    <div className="w-full ">
                        <div>
                    <h1 className="text-black text-5xl font-semibold font-ralewayReg">IntelliCharge Chargers</h1>
                    <h3 className="mt-4 text-black text-3xl font-ralewayReg">Sleek and robust chargers for easy integration Level 2 charging at 40A and 80A.</h3>
                        </div>
                        <div className="mt-12 w-full pt-6 pb-6 border-t-[2px] border-b-[1px] border-secondary">
                            <h2 className="text-black font-blender text-3xl">Robust Connectivity</h2>
                            <h3 className="text-lg text-black">Connect your charger to the cloud with WiFi or cellular to ensure reliable network connection.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[1px] border-secondary">
                            <h2 className="text-black font-blender text-3xl">Comprehensive Certifications </h2>
                            <h3 className="text-lg text-black">UL certified, OCPP compliant, fully weatherized.</h3>
                        </div>
                        <div className="w-full pt-6 pb-6 border-t-[1px] border-b-[2px] border-secondary">
                            <h2 className="text-black font-blender text-3xl">Secure Design</h2>
                            <h3 className="text-lg text-black">Simple LED indicators effectively communicate statuses while reducing susceptibility to vandalism.</h3>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full flex items-center justify-end col-span-1 hidden sm:block">
                    <div className="flex items-center justify-center">
                        <img className="w-[60%]" src="/images/chargerFrontFacing-removebg-preview.png"></img>
                    </div>
                </div>
            </div> */}
            <Footer />
            {clickedImg !== "none" && (
                createPortal(
                    <div className={`right-0 top-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-70 fixed z-[9999] pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pl-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48`}>
                        <div className="relative p-2 w-[90%] large-laptop:w-[92%] desktop:w-[80%]">
                            <button onClick={() => setClickedImg("none")} className="absolute -top-12 -right-12 h-12  w-12 rounded-full bg-white z-50 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </button>
                            {clickedImg === "monitor" && (
                                <img className="w-full" src="/images/intellichargePaymentSS.png" alt="Monitor" />
                            )}
                        </div>
                    </div>
                    , document.body
                )
            )}
        </div>
    )
}

export default NewIntellicharge
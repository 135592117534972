
import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"

function Recognition() {
    return (
        <div className="w-full h-full bg-baseWhite">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
            <VideoBackground
                    source={"powerline_clip.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-[60px] leading-[60px] font-medium font-blender">Awards and Recognition</h2>
                    </div>
                </div>
            </div>
            <div className="w-full grid grid-cols-3 gap-8 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                    <h2 className="font-semibold text-4xl border-b-[3px] border-secondary pb-2 font-blender">DOE Digitizing Award</h2>
                    <p className="mt-4">Grid Elevated was selected as one of 9 winners of the DOE Office of Electricity Digitizing Utilities Prize Round 2. Grid Elevated was selected to develop the Multi-Source Data Acquisition, Analytics, Sharing, and Security (MIDAAS) Platform, a toolkit of secure data analytic modules built upon Grid Elevated’s data acquisition solution with Heber Light & Power. The MIDAAS Platform provides the infrastructure to translate large amounts of data recently enabled by the energy transition to meaningful information to impact decision-making.</p>
                    <a href="https://www.herox.com/DigitizingUtilitiesRound2/update/6954" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                        <h2 className="font-blender text-white text-lg">Learn More </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </a>
                </div>
                <div className="hidden sm:block col-span-1 flex items-center">
                    <div className="">
                        <img className="max-h-96" src="/images/digitizingAward.jpeg"></img>
                    </div>
                </div>
            </div>
            {/* <div className="w-full grid grid-cols-3 gap-8 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                    <h2 className="font-semibold text-4xl border-b-[3px] border-secondary pb-2 font-ralewayReg">DOE CESER SBIR Phase I Recipient</h2>
                    <p className="mt-4">Grid Elevated received a Phase I Small Business Innovation Research (SBIR) grant from the DOE Cybersecurity , Energy Security, and Emergency Response Office. This project will develop SecurEV, an AI-powered real-time cyber defense technology for EV charging infrastructure in commercial applications to mitigate the impacts of cyberattacks on the infrastructure.</p>
                    <a href="https://science.osti.gov/sbir/Awards" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                        <h2 className="font-blender text-white text-lg">Learn More </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </a>
                </div>
                <div className="hidden sm:block col-span-1 flex items-center">
                    <div className="">
                        <img className="max-h-96" src="/images/DOECESER.png"></img>
                    </div>
                </div>
            </div> */}
            {/* <div className="w-full grid grid-cols-3 gap-8 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                    <h2 className="font-semibold text-4xl border-b-[3px] border-secondary pb-2 font-ralewayReg">DOE WPTO SBIR Phase I Recipient</h2>
                    <p className="mt-4">Grid Elevated received a Phase I Small Business Innovation Research (SBIR) grant from the DOE Water Power Technologies Office.This project proposes to develop the Open Hydropower Data Protocol (OHDP), which is a computer library designed to facilitate the access to the data needed to support advanced flexible hydropower operation.</p>
                    <a href="https://science.osti.gov/sbir/Awards" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                        <h2 className="font-blender text-white text-lg">Learn More </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </a>
                </div>
                <div className="hidden sm:block col-span-1 flex items-center">
                    <div className="">
                        <img className="max-h-96" src="/images/DOEStamp.png"></img>
                    </div>
                </div>
            </div> */}
            <div className="w-full grid grid-cols-3 gap-8 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-10 laptop:pt-12 large-laptop:pt-16 desktop:pt-16 pb-6 md:pb-8 laptop:pb-8 large-laptop:pb-10 desktop:pb-12 ">
                <div className="col-span-3 sm:col-span-2 flex flex-col justify-center">
                    <h2 className="font-semibold text-4xl border-b-[3px] border-secondary pb-2 font-blender">DOE WPTO H2Os Grand Prize Winner</h2>
                    <p className="mt-4">A team of engineers from Grid Elevated and the Utah Smart Energy Lab at the University of Utah won all three phases of the DOE Water Power Technologies Office Hydropower Operations Optimization (H2Os). The competition required teams to develop optimization models to optimize water systems and hydropower generation while considering power system constraints. The team developed HydroFlex, a cloud-based software designed to solve the hydropower generation problem. See a demonstration of the platform.</p>
                    <a href="https://www.energy.gov/eere/articles/doe-announces-winners-hydropower-operations-optimization-prize" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-4 w-[136px]">
                        <h2 className="font-blender text-white text-lg">Learn More </h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </a>
                </div>
                <div className="hidden sm:block col-span-1 flex items-center">
                    <div className="">
                        <img className="max-h-96" src="/images/hydroflexAward.png"></img>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Recognition
import { useState } from "react"
import { Link } from "react-router-dom"

import Footer from "../globalComponents/footer"
import VideoBackground from "./videoBackground"

function Home() {

    const [mouseOverIntelliCharge, setMouseOverIntelliCharge] = useState(false)
    const [mouseOverSecurEV, setMouseOverSecurEV] = useState(false)
    const [mouseOverMIDAAS, setMouseOverMIDAAS] = useState(false)

    return (
        // pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pr-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-24 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32
        <div className="w-full h-full bg-gray-100">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"homePagePowerLineBG.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-4xl leading-[34px] mobile:text-[60px] mobile:leading-[60px] w-full lg:w-[70%] xl:w-[53%] font-medium font-blender">A grid transformation is underway. We are here to bring the intelligence.</h2>
                        <h3 className="text-white text-md mobile:text-xl font-regular mt-2 w-full lg:w-[70%] xl:w-[53%] font-ralewayReg">Grid Elevated leads the charge as the world develops needed solutions to bring the power of AI, data, and analytics to meet the challenges of today’s evolving power grid.</h3>
                        {/* <div className="sm:flex mt-4">
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <div className="p-24 bg-white">
                        <h2 className="text-black text-[140px] font-blender">IntelliCharge</h2>
                        <h2 className="text-black text-[140px] font-blender mt-10 font-semibold">IntelliCharge</h2>
            </div> */}
            <div className="bg-baseGray min-h-[100vh] pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-56 pb-16 md:pb-20 laptop:pb-20 large-laptop:pb-24 desktop:pb-24">
                <h2 className="z-40 text-4xl top-0 left-0 w-full text-left text-textWhite font-ralewayReg lg:w-[70%] large-laptop:w-[70%] desktop:w-[60%]">
                Our solutions bring the power of AI, the potential of big data, and the grid together with software technologies and hardware tools to transform not only the grid but the way it's operated.
                </h2>
                <div className="grid grid-cols-12 gap-y-10 md:gap-y-0 md:gap-12 mt-20">
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 md:pr-8 ">
                        <img src="/images/intellicharge_icon.png" className="h-36 -mb-2" />
                        <Link to="/intellicharge" onMouseEnter={() => setMouseOverIntelliCharge(true)} onMouseLeave={() => setMouseOverIntelliCharge(false)} className="flex items-center mt-6 border-b-[3px] border-secondary w-full text-start pb-1">
                            <h2 className="text-5xl text-textWhite font-blender">
                                IntelliCharge
                            </h2>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${mouseOverIntelliCharge ? "ml-8" : "ml-4"} size-8 duration-100 transform text-textWhite`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Link>
                        <p className="text-secondaryTextWhite text-xl mt-2">End-to-end solutions provide intelligence, resilience, and security at the device, grid, and operator levels.</p>
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 md:pr-8 ">
                        <img src="/images/securev_icon.png" className="h-36 -mb-0" />
                        <Link to="/securev" onMouseEnter={() => setMouseOverSecurEV(true)} onMouseLeave={() => setMouseOverSecurEV(false)} className="flex items-center text-5xl mt-4 text-textWhite font-blender border-b-[3px] border-secondary w-full text-start pb-1">
                            SecurEV
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${mouseOverSecurEV ? "ml-8" : "ml-4"} size-8 duration-100 transform text-textWhite`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Link>
                        <p className="text-secondaryTextWhite text-xl mt-2">Bring cybersecurity to every aspect of your EV charging network no matter your hardware.</p>
                    </div>
                    <div className="col-span-12 md:col-span-6 large-laptop:col-span-4 md:pr-8 ">
                        <img src="/images/new_midaas_icon.png" className="h-36 -mb-0" />
                        <Link to="/midaas" onMouseEnter={() => setMouseOverMIDAAS(true)} onMouseLeave={() => setMouseOverMIDAAS(false)} className="flex items-center text-5xl mt-4 text-textWhite font-blender border-b-[3px] border-secondary w-full text-start pb-1">
                            MIDAAS
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${mouseOverMIDAAS ? "ml-8" : "ml-4"} size-8 duration-100 transform text-textWhite`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </Link>
                        <p className="text-secondaryTextWhite text-xl mt-2">Empower intelligent utility decision-making by providing data acquisition, analytics, and sharing in a unified platform.</p>
                    </div>

                </div>
            </div>
            {/* <div className="relative h- -ml-3 -mr-3 bg-cover">
                <img src="/images/stock_home4.png" alt="Power grid image" className="w-full h-full object-cover" />
                </div> */}
            <div className="grid grid-cols-2 gap-4 pt-20 pb-14 lg:h-[100vh]">
                <div className="col-span-2 md:col-span-1 flex items-center justify-center">
                    <h2 className="z-40 text-4xl text-black w-full font-ralewayReg text-left pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 ">
                        End-to-end solutions provide <span className="text-secondary font-semibold">intelligence, resilience, and security</span> at the device, grid, and operator-level.
                    </h2>
                </div>
                <div className=" col-span-2 md:col-span-1 p-8 large-laptop:p-20">
                    <img src="/images/homePageGraphicRounded.png" alt="Power grid image" className="w-full h-full object-contain" />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home











































{/* <div className="z-20 h- w-full bg-cover bg-baseGray pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pr-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48">
                <div className="w-full">
                    <div className="relative w-full ">
                        <img src="/images/whiteArrows.png" className="w-[80%] ml-[10%]"></img>
                        <div className="absolute  -top-14 left-0 h-full w-full flex flex-col justify-center items-center">
                            <div className="border-[1px] homeGradient p-2 pt-3 rounded-lg ">
                                <h2 className="text-textWhite text-4xl font-semibold text-center pl-24 pr-24 h-12 -mb-1">Grid Elevated Ecosystem</h2>
                                <p className="text-textWhite text-lg text-center mt-0 pl-6 pr-6">Explore a rich ecosystem of products built to work together, save your company money, and save you time.</p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex justify-between pl-3 pr-3">
                        <div className="large-laptop:w-96 w-[30%] h-64 z-20">
                            <h2 className="text-textWhite text-4xl font-blender text-center">MIDAAS</h2>
                            <div className="w-full h-full bg-secondaryTint bg-opacity-80 p-3 rounded-lg border-[1px] border-white mt-1">
                                <p className="text-textWhite">Text describing MIDAAS</p>
                            </div>
                        </div>
                        <div className="large-laptop:w-96 w-[30%] h-64 ">
                            <h2 className="text-textWhite text-4xl font-blender text-center">IntelliCharge</h2>
                            <div className="w-full h-full bg-primarySecondaryTintCombo p-3 bg-opacity-80 rounded-lg border-[1px] border-white mt-1">
                                <p className="text-textWhite">Text describing IntelliCharge</p>
                            </div>
                        </div>
                        <div className="large-laptop:w-96 w-[30%] h-64 ">
                            <h2 className="text-textWhite text-4xl font-blender text-center">SecurEv</h2>
                            <div className="w-full h-full bg-primaryTint bg-opacity-100 p-3 rounded-lg border-[1px] border-white mt-1">
                                <p className="text-textWhite">Text describing SecurEv</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
{/* <div className="w-full h-[60vh] flex bg-secondaryTint pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pr-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48">
                <div className="w-[50%] pr-4">
                    <h2 className="text-7xl text-white font-semibold font-blender">MIDAAS</h2>
                    <h3 className="text-textWhite text-2xl italic -mt-1">"Make load management easier"</h3>
                    <p className="w-[70%] text-textWhite mt-6">Improve your load management solutions with access to thousands of different sensors across all US states. </p>
                    <button className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-8">
                        <h2 className="text-white text-lg font-mono font-semibold">Learn More</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
                <div className="w-[50%]">
                                <img src="/images/MidaasDashboardSS.png" className="rounded-lg border-[3px] border-secondary"></img>
                </div>
            </div>
            <div className="w-full">
                <img src="/images/secondaryTintToComboBg.png" className="w-full h-96"></img>
            </div>
            <div className="w-full h-[60vh] bg-primarySecondaryTintCombo pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pr-56 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48">
                <h2 className="text-5xl text-white font-semibold font-blender w-full text-center">IntelliCharge</h2>
                <div></div>
            </div> */}
{/* <div className="z-20 h- w-full homeGradient pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-32 large-laptop:pr-32 desktop:pl-56 desktop:pr-56 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:bg-48">
                <h2 className="text-black text-4xl font-medium">Cloud-based AI solutions for power grid applications.</h2>
                <div className="grid grid-cols-2">
                    <div className="col-span-1 grid grid-cols-6 gap-4 justify-between w-full mt-10">
                        <div className=" col-span-12 sm:col-span-6 xl:col-span-3 w-full bg-white bg-opacity-100 shadow-lg text-center w-[25%] p-3 pb-8 pt-8 flex flex-col items-center mr-4 rounded-lg border-[2px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z" />
                            </svg>
                            <h2 className="text-xl font-semibold mt-2 mb-1">Accessible Innovation</h2>
                            <p>Cloud-based solutions that are accessible anytime and anywhere, and affordable for electric utilities of any size.</p>
                        </div>
                        <div className=" col-span-12 sm:col-span-6 xl:col-span-3 w-full bg-white bg-opacity-100 shadow-lg text-center w-[25%] p-3 pb-8 pt-8 flex flex-col items-center mr-4 rounded-lg border-[2px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                            </svg>
                            <h2 className="text-xl font-semibold mt-2 mb-1">Artificial Intelligence</h2>
                            <p>We use artificial intelligence to find rapid solutions to complex problems in the power system.</p>
                        </div>
                        <div className=" col-span-12 sm:col-span-6 xl:col-span-3 w-full bg-white bg-opacity-100 shadow-lg text-center w-[25%] p-3 pb-8 pt-8 flex flex-col items-center mr-4 rounded-lg border-[2px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                            </svg>
                            <h2 className="text-xl font-semibold mt-2 mb-1">Data Driven</h2>
                            <p>Efficient data acquisition and analytics for enhancing situational awareness and informing operation.</p>
                        </div>
                        <div className=" col-span-12 sm:col-span-6 xl:col-span-3 w-full bg-white bg-opacity-100 shadow-lg text-center w-[25%] p-3 pb-8 pt-8 flex flex-col items-center rounded-lg border-[2px] border-primaryAccent">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-primaryAccent">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                            <h2 className="text-xl font-semibold mt-2 mb-1">Cybersecure</h2>
                            <p>Innovative embedded security measures to facilitate a safe and secure utilization of solutions.</p>
                        </div>
                    </div>
                    <div className="col-span-1 flex items-center justify-center">
                        <img src="/images/pngGreenEnergyAsset.png" className="w-[75%]"></img>
                    </div>
                </div>
                <div className="">
                <h2 className="text-black text-4xl font-medium mt-24">Our Partners</h2>
                <div className="grid grid-cols-12 gap-3 mt-6">
                    <div className="col-span-6  sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex items-center p-5">
                        <div className="w-full aspect-w-1 aspect-h-1">
                            <img src="/images/pacificorp-removebg-preview.png"></img>
                        </div>
                    </div>
                    <div className="col-span-6  sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex items-center p-5">
                        <div className="w-full aspect-w-1 aspect-h-1">
                            <img src="\images\heberLightAndPowerLogo.png"></img>
                        </div>
                    </div>
                    <div className="col-span-6  sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex items-center p-5">
                        <div className="w-full aspect-w-1 aspect-h-1">
                            <img src="\images\utahCleanCitiesLogo.png"></img>
                        </div>
                    </div>
                    <div className="col-span-6  sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex items-center p-5">
                        <div className="w-full aspect-w-1 aspect-h-1">
                            <img src="\images\leadersForCleanAirLogo.png"></img>
                        </div>
                    </div>
                    <div className="col-span-6  sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex items-center p-5">
                        <div className="w-full aspect-w-1 aspect-h-1">
                            <img src="\images\u_of_u.png"></img>
                        </div>
                    </div>
                    <div className="col-span-6  sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex items-center p-5">
                        <div className="w-full aspect-w-1 aspect-h-1">
                            <img src="\images\usmart_logo.png"></img>
                        </div>
                    </div>
                </div>
                </div>
            </div> */}
import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"

function News() {
    return (
        <div className="w-full h-full bg-baseWhite">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
            <VideoBackground
                    source={"powerline_clip.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-[60px] leading-[60px] font-medium font-blender">News</h2>
                    </div>
                </div>
            </div>
            <div className="pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <h3 className="text-xl laptop:text-2xl font-semibold pb-1 md:w-[35%] font-ralewayReg">See recent Grid Elevated News</h3>
                <h3 className="text-3xl font-semibold pb-1 mt-20 md:w-[42%] font-ralewayReg border-b-[3px] border-secondary">Partnership with Heber Light & Power</h3>
                <a href="https://www.publicpower.org/periodical/article/heber-power-light-hingham-municipal-lighting-plant-grid-projects-detailed" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-2 w-[136px]">
                    <h2 className="font-blender text-white text-lg">Learn More </h2>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </a>
                <h3 className="text-3xl font-semibold pb-1 mt-8 md:w-[42%] font-ralewayReg border-b-[3px] border-secondary">Grid Elevated wins all phases of DOE competition</h3>
                <a href="https://www.energy.gov/eere/articles/doe-announces-winners-hydropower-operations-optimization-prize" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-2 w-[136px]">
                    <h2 className="font-blender text-white text-lg">Learn More </h2>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </a>
                <h3 className="text-3xl font-semibold pb-1 mt-8 md:w-[42%] font-ralewayReg border-b-[3px] border-secondary">Grid Elevated wins first phase of DOE competition</h3>
                <a href="https://www.price.utah.edu/2023/01/30/ece-team-wins-top-doe-prize" className="flex items-center bg-secondary pl-3 pr-3 p-2 rounded-md mt-2 w-[136px]">
                    <h2 className="font-blender text-white text-lg">Learn More </h2>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white ml-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </a>
            </div>
            <Footer />
        </div>
    )
}

export default News
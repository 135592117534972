import { Helmet } from 'react-helmet'

function GoogleAnalytics() {
    return (
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-174CL40MHM"></script>
            <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-174CL40MHM');
        `}
            </script>
        </Helmet>
    )
}

export default GoogleAnalytics
function VideoBackground({
  source
}) {
  return (
    <div className="absolute top-0 left-0 w-[100vw] h-[100vh] overflow-hidden -z-10">
      <video
        autoPlay
        muted
        playsInline
        loop
        className="absolute top-1/2 left-1/2 w-full h-full object-cover transform -translate-x-1/2 -translate-y-1/2">
        <source src={`/videos/${source}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
export default VideoBackground;


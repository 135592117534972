import Footer from "../globalComponents/footer"
import VideoBackground from "../home/videoBackground"

function Midaas({

}) {
    return (
        <div className="w-full h-full">
            <div className="h-screen w-full bg-transparent z-20 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32 relative">
                <VideoBackground
                    source={"MIDAASVid_1920_1080_24fps.mp4"}
                />
                <div className="w-full h-full flex items-end ">
                    <div>
                        <h2 className="text-white text-4xl leading-[34px] mobile:text-[60px] mobile:leading-[60px] w-full lg:w-[70%] xl:w-[53%] font-medium font-blender">Become data driven without the tech overhead</h2>
                        <h3 className="text-white text-md mobile:text-xl font-regular mt-2 w-full lg:w-[70%] xl:w-[53%] font-ralewayReg">MIDAAS empowers intelligent utility decision-making by providing comprehensive data acquisition, analytics, and sharing in a unified platform.</h3>
                    </div>
                </div>
            </div>
            <div className="w-full laptop:min-h-[100vh] bg-baseGray bg-top px-6 md:px-10 laptop:px-16 large-laptop:px-24 desktop:px-42 py-8 md:py-16 laptop:py-24 large-laptop:py-32 desktop:py-32 flex flex-col items-center justify-center">
                <h2 className="z-40 text-xl laptop:text-2xl text-center text-textWhite font-ralewayReg w-full lg:w-[70%] large-laptop:w-[53%] desktop:w-[40%]">
                    MIDAAS is a cloud-based platform with web interfaces and APIs for seamless integration into day-to-day operations.
                </h2>
                <div className="w-full flex items-center justify-center large-laptop:px-32 desktop:pr-48 desktop:pl-48">
                    <img src="/images/midaas3.png" className="mt-12 laptop:mt-12 hidden mobile:block w-[100%] laptop:w-[90%] md:w-[80%]"></img>
                </div>
            </div>
            <div className="w-full min-h-[100vh] grid grid-cols-12 laptop:gap-20 bg-baseWhite bg-top pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 pt-8 md:pt-16 laptop:pt-20 large-laptop:pt-24 desktop:pt-28 pb-16 md:pb-20 laptop:pb-24 large-laptop:pb-32 desktop:pb-32">
                <div className="col-span-12 laptop:col-span-5 flex items-center">
                    <div className="w-full ">
                        <div>
                            <h1 className="text-5xl font-semibold font-blender">MIDAAS translates big data to meaningful information for grid operators</h1>
                            {/* <h2 className="text-3xl mt-3 font-ralewayReg">MIDAAS translates big data to meaningful information.</h2> */}
                        </div>
                        <div className="mt-12 w-full pt-7 pb-7 border-t-[2px] border-b-[1px] border-secondary">
                            <h3 className="text-black text-xl">Immediately gain access to 10,000+ sensors acquiring real-time information.</h3>
                        </div>
                        <div className="w-full pt-7 pb-7 border-t-[1px] border-b-[1px] border-secondary">
                            <h3 className="text-black text-xl">Customize your account to acquire data that is meaningful to you and view it in your custom dashboard.</h3>
                        </div>
                        <div className="w-full pt-7 pb-7 border-t-[1px] border-b-[1px] border-secondary">
                            <h3 className="text-black text-xl">Utilize AI-based data analytics to pull critical performance metrics.</h3>
                        </div>
                        <div className="w-full pt-7 pb-7 border-t-[1px] border-b-[1px] border-secondary">
                            <h3 className="text-black text-xl">Securely integrate private datasets to view alongside other data.</h3>
                        </div>
                        <div className="w-full pt-7 pb-7 border-t-[1px] border-b-[2px] border-secondary">
                            <h3 className="text-black text-xl">Share data with partners and communicate decision-making.</h3>
                        </div>
                    </div>
                </div>
                <div className="hidden laptop:col-span-7 laptop:flex items-center justify-center">
                    <img src="/images/midaas_dashboard_collage.png" className="w-[100%] sm:w-[90%] md:w-[100%]"></img>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Midaas
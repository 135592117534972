

function MidaasPrivacy() {
    return (
        <div className="overflow-y-scroll min-h-screen bg-gray-300 flex items-center large-laptop:px-96">
            <div className="w-[700px] mt-28 mb-28">
                <h1 className="text-xl font-bold">Terms and Conditions for Grid Elevated MIDAAS Platform</h1>
                <h2 className="mt-5 text-lg font-bold">1. Introduction</h2>
                <p>Welcome to MIDAAS, a cloud-based data platform. These terms and conditions
                    outline the rules and regulations for the use of Grid Elevated's platform. By
                    accessing or using our platform, you agree to comply with these terms. If you
                    disagree with any part of these terms, please discontinue your use of the platform.</p>
                <h2 className="mt-5 text-lg font-bold">2. Definitions</h2>

                <li><span className="font-semibold">Platform:</span> The cloud-based data platform provided by Grid Elevated,
                    including any services, tools, software, or other resources available.</li>
                <li><span className="font-semibold">User:</span> Any individual or entity who accesses or uses the platform.</li>
                <li><span className="font-semibold">Data:</span> Any data, information, or content uploaded, processed, or stored by
                    the user on the platform.</li>

                <h2 className="mt-5 text-lg font-bold">3. Account Registration</h2>

                <li>To use the platform, users may be required to register and create an
                    account.</li>
                <li>Users are responsible for maintaining the confidentiality of their account
                    credentials and are liable for any activities conducted under their account.</li>
                <li>Grid Elevated reserves the right to suspend or terminate accounts found in
                    violation of these terms.</li>

                <h2 className="mt-5 text-lg font-bold">4. Use of the Platform</h2>

                <li>The platform is intended for lawful data storage, processing, and analysis.
                    Users must comply with all applicable laws and regulations when using the
                    platform.</li>
                <li>Users are prohibited from uploading or processing any illegal, harmful, or
                    offensive content.</li>
                <li>Users shall not attempt to interfere with or disrupt the platform's security,
                    network, or infrastructure.</li>

                <h2 className="mt-5 text-lg font-bold">5. Data Ownership and Privacy</h2>
                <li>Users retain ownership of any data they upload or store on the platform.</li>
                <li>Grid Elevated acts as a data processor and will not access, use, or disclose
                    user data except as required to provide the service or as permitted by the
                    user.</li>
                <li>Grid Elevated implements industry-standard security measures to protect
                    user data but cannot guarantee the absolute security of the data.</li>
                <h2 className="mt-5 text-lg font-bold">6. Subscription and Fees</h2>
                <li>The platform may offer free and paid subscription plans. Fees for paid plans
                    will be disclosed at the time of subscription.</li>
                <li>Payments are due in accordance with the subscription terms and are non-
                    refundable, except as required by law.</li>
                <li>Grid Elevated reserves the right to change fees or introduce new charges
                    with prior notice to the user.</li>
                <h2 className="mt-5 text-lg font-bold">7. Service Availability and Performance</h2>
                <li>Grid Elevated strives to ensure the availability and performance of the
                    platform but cannot guarantee uninterrupted access due to maintenance,
                    updates, or other factors.</li>
                <li>Grid Elevated is not liable for any downtime or disruptions to the platform's
                    services, though reasonable efforts will be made to minimize impact.</li>
                <h2 className="mt-5 text-lg font-bold">8. Limitation of Liability</h2>
                <li>Grid Elevated is not liable for any direct, indirect, incidental, or
                    consequential damages arising from the use of the platform.</li>
                <li>The platform is provided "as-is" without warranties of any kind, whether
                    express or implied, including but not limited to the implied warranties of
                    merchantability, fitness for a particular purpose, or non-infringement.</li>
                <h2 className="mt-5 text-lg font-bold">9. Termination</h2>
                <li>Grid Elevated reserves the right to suspend or terminate access to the
                    platform for users found to be in violation of these terms.</li>
                <li>Users may terminate their account at any time, but they are responsible for
                    any outstanding payments due under their subscription plan.</li>
                <h2 className="mt-5 text-lg font-bold">10. Governing Law</h2>
                <li>These terms shall be governed by and construed in accordance with the laws
                    of the United States of America, without regard to its conflict of law
                    principles.</li>
                <li>Any disputes arising from these terms or the use of the platform will be
                    resolved through binding arbitration in Salt Lake City, Utah.</li>
                <h2 className="mt-5 text-lg font-bold">11. Modifications to Terms</h2>
                <li>Grid Elevated reserves the right to update or modify these terms at any
                    time. Users will be notified of any significant changes.</li>
                <li>Continued use of the platform following any changes to the terms will be
                    deemed acceptance of the revised terms.</li>
                <h2 className="mt-5 text-lg font-bold">12. Contact Information</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <h2 className="font-bold text-lg mt-1">Grid Elevated</h2>
                <p className="mt-0">Email: <span className="text-blue-600">info@gridelevated.com</span>.</p>
            </div>
        </div>
    )
}

export default MidaasPrivacy
import { Link } from "react-router-dom"

function Footer() {
    return (
        <div className="min-h-72 w-full bg-baseGray grid grid-cols-12 pl-6 pr-6 md:pl-10 md:pr-10 laptop:pl-16 laptop:pr-16 large-laptop:pl-24 large-laptop:pr-24 desktop:pl-42 desktop:pr-42 ">
            <div className="col-span-4 headerBreak:col-span-3 h-full flex flex-col items-start p-3 pt-6">
                <h3 className="text-textWhite font-semibold text-lg">Solutions</h3>
                <Link to="/intellicharge">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-2">IntelliCharge</h3>
                </Link>
                <Link to="/securev">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">SecurEV</h3>
                </Link>
                <Link to="/midaas">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">MIDAAS</h3>
                </Link>
            </div>
            {/* <div className="w-[25%] h-full flex flex-col items-start p-3 pt-6">
                <h3 className="text-textWhite font-semibold text-lg">News:</h3>
            </div> */}
            <div className="col-span-4 headerBreak:col-span-3 h-full flex flex-col items-start p-3 pt-6">
                <h3 className="text-textWhite font-semibold text-lg">Company</h3>
                <Link to="/aboutus">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-2">About Us</h3>
                </Link>
                <Link to="/careers">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">Careers</h3>
                </Link>
                {/* <Link to="/contact">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">Contact Us</h3>
                </Link> */}
                <Link to="/recognition">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">Recognition</h3>
                </Link>
                <Link to="/news">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">News</h3>
                </Link>
                <Link to="/intellicharge/privacy">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">Privacy</h3>
                </Link>
            </div>
            <div className="col-span-4 headerBreak:col-span-3 h-full flex flex-col items-start p-3 pt-6">
                <h3 className="text-textWhite font-semibold text-lg">Customers</h3>
                {/* <Link to="/">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-1">Success Stories</h3>
                </Link> */}
                <Link to="/contact">
                    <h3 className="text-secondaryTextWhite text-sm headerBreak:text-lg font-ralewayReg mt-2">Contact Us</h3>
                </Link>
            </div>
            <div className="col-span-12 h-full flex flex-col items-center p-3 pt-6">
                {/* <h3 className="text-textWhite font-semibold text-lg">Other:</h3>
                <Link to="/">
                <h3 className="text-secondaryTextWhite text-lg font-ralewayReg mt-1">Partners</h3>
                </Link> */}
                <div className="flex items-center">
                <a href="https://www.linkedin.com/company/grid-elevated/about/">
                <svg role="img" className="w-8 text-[#0A66C2]" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path fill="currentColor" d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                </a>
                <a href="https://www.youtube.com/channel/UC4qC7wjth2T21FkVZsFKniw">
                <svg role="img" className="w-10 text-[#FF0000] ml-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path fill="currentColor" d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
                </a>
                </div>
                <h2 className="mt-4 text-textWhite text-sm">Copyright &copy; 2024, Grid Modernization Solutions, LLC. All rights reserved. </h2>
            </div>
        </div>
    )
}

export default Footer